const Login = () => import('@/views/user/Login.vue');
const Register = () => import('@/views/user/Register.vue');
const Forgot = () => import('@/views/user/Forgot.vue');
const ResetPassword = () => import('@/views/user/ResetPassword.vue');
const Me = () => import('@/views/user/Me.vue');
const Message = () => import('@/views/user/Message.vue');
const UpdateLog = () => import('@/views/user/UpdateLog.vue');

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '登录'
        }
    }, {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '注册'
        }
    },{
        path: '/forgot',
        name: 'forgot',
        component: Forgot,
        meta: {
            title: '重置密码'
        }
    },{
        path: '/resetPassword',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            title: '重置密码'
        }
    },
    {
        path: '/me',
        name: 'me',
        component: Me,
        meta: {
            title: '个人信息'
        }
    },
    // {
    //     path: '/update-log',
    //     name: 'update-log',
    //     component: UpdateLog,
    //     meta: {
    //         title: '更新日志'
    //     }
    // },
    {
        path: '/message',
        name: 'message',
        component: Message,
        meta: {
            title: '消息通知'
        }
    },

]
