<template>
  <div id="foot">
    <div id="about">
      <span class="c999">©2019 lanshan.red All right reserved.</span> &nbsp;&nbsp;&nbsp;&nbsp;

    </div>
  </div>
</template>

<script>
    export default {
        name: "Foot"
    }
</script>

<style scoped>
    #foot {
        width:100%;
        text-align: left;
        margin:0 auto;
        margin-top: 40px;
        margin-bottom: 20px;
        overflow: hidden;
    }
    .fr{
        float:right;
    }
    #about{
        padding: 10px 20px;
    }
    a.beian  {
        color: #2d2d2d;
    }
    a.beian:hover{
        color: #2d2d2d;
        text-decoration:underline;
    }
    .c999{
        color:#999;
    }
</style>
