export default {
    product_manage: [
        {
            module_name:'product_module',//货品模块
            page_name: 'product_list', //货品列表
            rights: [
                'view_product_list',
                'edit_product_list',
            ],
        },

        {
            module_name:'stock_module',//库存单据
            page_name: 'goods_explode_change', //商品拆装单
            rights: [
                'view_explode_change',
                'edit_explode_change',
                'valid_explode_change',
            ],
        },


        {
            module_name:'manage_module',//库存管理
            page_name: 'goods_inventory', //库存盘点
            rights: [
                'view_goods_inventory',
                'edit_goods_inventory',
                'valid_goods_inventory',
                'operate_goods_inventory'
            ],
        },

        {
            module_name:'manage_module',//库存管理
            page_name: 'stock_log', //库存记录
            rights: [
                'view_stock_log',
            ],
        },

    ],

    buy_manage: [
        {
            module_name:'buy_module',//采购模块
            page_name: 'procurement_list', //采购列表
            rights: [
                'view_procurement_list',
                'edit_procurement_list',
                'valid_procurement_list',
                'operate_procurement_list',//流程操作
                'force_procurement_list',//强制操作
            ],
        },
        {
            module_name:'buy_module',//采购模块
            page_name: 'buy_print', //采购打印
            rights: [
                'view_buy_print',
                'edit_buy_print',
            ],
        },
    ],

    sale_manage: [
        {
            module_name:'sale_module',//销售模块
            page_name: 'sale_list', //销售列表
            rights: [
                'view_sales_list',
                'edit_sales_list',
                'valid_sales_list',
                'operate_sales_list',//流程操作
                'force_sales_list'//
            ],
        },
        {
            module_name:'sale_module',//销售模块
            page_name: 'sale_exchange', //销售列表
            rights: [
                'view_sale_exchange',
                'edit_sale_exchange',
                'valid_sale_exchange',
            ],
        },

        {
            module_name:'sale_price_module',//销售售价模块
            page_name: 'sale_price_manage', //销售售价管理
            rights: [
                'view_sale_price',
                'edit_sale_price',
                'valid_sale_price',
            ],
        },
        {
            module_name:'sale_price_module',//销售售价模块
            page_name: 'sale_print_manage', //销售售价管理
            rights: [
                'view_sale_print',
                'edit_sale_print',
            ],
        },
        {
            module_name:'sale_contract_module',//销售模块
            page_name: 'sale_contract_manage', //销售合同列表
            rights: [
                'view_sale_contract',
                'edit_sale_contract',
                'valid_sale_contract',
            ],
        },
        {
            module_name:'sale_report_module',//销售报表模块
            page_name: 'sale_goods_count', //货品销量
            rights: [
                'view_sale_goods_count',
            ],
        },
        {
            module_name:'sale_report_module',//销售报表模块
            page_name: 'sale_money_count', //货品销售额
            rights: [
                'view_sale_money_count',
            ],
        }
    ],

    financial_center: [
        {
            module_name:'financial_settlement',//财务结算
            page_name: 'financial_overview', //财务预览
            rights: [
                'view_financial_overview',
            ],
        },
        {
            module_name:'invoicing_settlement',//进销结算
            page_name: 'purchase_settlement', //采购结算
            rights: [
                'view_purchase_settlement',
                'operate_purchase_settlement', //流程操作
            ],
        },
        {
            module_name:'invoicing_settlement',//进销结算
            page_name: 'sales_settlement', //销售结算
            rights: [
                'view_sales_settlement',
                'operate_sales_settlement',//流程操作
            ],
        },
        {
            module_name:'extra_settlement',//额外结算
            page_name: 'miscellaneous_settlement', //杂项结算
            rights: [
                'view_miscellaneous_settlement',
                'edit_miscellaneous_settlement',
                'valid_miscellaneous_settlement',
                'operate_miscellaneous_settlement'
            ],
        },
        {
            module_name:'extra_settlement',//额外结算
            page_name: 'logistics_settlement', //销售结算
            rights: [
                'view_logistics_settlement',
                'operate_logistics_settlement',//流程操作
            ],
        },
    ],

    common_setting: [
        {
            page_name: 'unit_overview',
            module_name: 'store_manage',
            rights: [
                'view_unit_overview',
                'edit_unit_overview',
            ],
        },
        {
            page_name: 'address_info',
            module_name: 'store_manage',
            rights: [
                'view_address_info',
                'edit_address_info',
            ],
        },
        {
            page_name: 'project_info',
            module_name: 'store_manage',
            rights: [
                'view_project_info',
                'edit_project_info',
                'valid_project_info',
            ],
        },
        {
            page_name: 'department_info',
            module_name: 'store_manage',
            rights: [
                'view_department_info',
                'edit_department_info',
                'valid_department_info',
            ],
        },
        {
            page_name: 'warehouse_setting',
            module_name: 'warehouse_manage',
            rights: [
                'view_warehouse_setting',
                'edit_warehouse_setting',
            ],
        },
        {
            page_name: 'area_setting',
            module_name: 'warehouse_manage',
            rights: [
                'view_area_setting',
                'edit_area_setting',
            ],
        },
        {
            page_name: 'location_setting',
            module_name: 'warehouse_manage',
            rights: [
                'view_location_setting',
                'edit_location_setting',
            ],
        },
        {
            page_name: 'client_info',
            module_name: 'current_unit',
            rights: [
                'view_client_info',
                'edit_client_info',
            ],
        },
        {
            page_name: 'supplier_info',
            module_name: 'current_unit',
            rights: [
                'view_supplier_info',
                'edit_supplied_info',
            ],
        },
        {
            page_name: 'logistics_info',
            module_name: 'current_unit',
            rights: [
                'view_logistics_info',
                'edit_logistics_info',
            ],
        },
        {
            module_name:'financial_setup',
            page_name: 'flow_category',
            rights: [
                'view_flow_category',
                'edit_flow_category',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'file_category',
            rights: [
                'view_file_category',
                'edit_file_category',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'goods_label',
            rights: [
                'view_goods_label',
                'edit_goods_label',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'buy_category',
            rights: [
                'view_buy_category',
                'edit_buy_category',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'client_category',
            rights: [
                'view_client_category',
                'edit_client_category',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'pay_desc',
            rights: [
                'view_pay_desc',
                'edit_pay_desc',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'unit_set',
            rights: [
                'view_unit_set',
                'edit_unit_set',
            ],
        },
        {
            module_name:'basic_parameters',
            page_name: 'picture_lib',
            rights: [
                'view_picture_lib',
                'edit_picture_lib',
            ],
        },
        {
            module_name:'account_authority',
            page_name: 'account_setting',
            rights: [
                'view_account_setting',
                'edit_account_setting',
                'valid_account_setting',
                'operate_account_setting'
            ],
        },
        {
            module_name:'account_authority',
            page_name: 'role_setting',
            rights: [
                'view_role_setting',
                'edit_role_setting',
                'valid_role_setting'
            ],
        },
    ],
}
