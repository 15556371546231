<template>
  <div id="header">
    <div id="header_info">
      <div id="logo" class="col web_name">
        <router-link to="/"><span class="font-douyu">{{ company_name }}</span></router-link>
      </div>
      <div v-show="moudleAllrights.product_manage" style="background: hsla(0,0%,100%,.3);position: absolute;left:150px;top:18px;width: 1px;height: 14px;"></div>
      <div id="menu">
        <Menu mode="horizontal" :theme="theme1" :active-name="title_selected" v-show="moudleAllrights.product_manage">
          <MenuItem name="goodsManage" to="/goodsManage">
            商品管理
          </MenuItem>
          <!-- <MenuItem name="createManage" to="/createManage" v-show="moudleAllrights.create_manage">
            生产管理
          </MenuItem> -->
          <MenuItem name="buyManage" to="/buyManage" v-show="moudleAllrights.buy_manage">
            采购管理
          </MenuItem>
          <MenuItem name="saleManage" to="/saleManage" v-show="moudleAllrights.sale_manage">
            销售管理
          </MenuItem>
          <MenuItem name="finance" to="/finance" v-show="moudleAllrights.financial_center">
            财务中心
          </MenuItem>
          <MenuItem name="setting" to="/setting" v-show="moudleAllrights.common_setting">
            综合设置
          </MenuItem>
        </Menu>
      </div>

      <div id="tools">
        <Dropdown v-if="login_flag" trigger="custom" :visible="info_visible" @on-clickoutside="infoHandleClose">
          <a href="javascript:void(0)" @click="infoHandleOpen">
            <Badge dot :count="msg.is_read==0?1:0">
              <Icon type="md-notifications-outline"/>
            </Badge>
          </a>
          <DropdownMenu slot="list">
            <Tabs value="1">
              <TabPane label="导出" name="1">
                <div class="list">
                  <Row v-for="item in msg.export">
                    <Col span="16">&nbsp;
                      <span class="fs-12">{{ item.title }}</span>
                    </Col>
                    <Col span="4">
                      <a :href="item.download_url" v-if="item.status == 2">
                        <Button type="text" size="small" class="main-font-color">
                          <Icon type="ios-download"/>
                          下载
                        </Button>
                      </a>
                    </Col>
                    <Col span="4">
                      <a target="_blank" :href="'/DataView?transfer_id='+item.transfer_id"
                         v-if="item.status == 2 && item.transfer_id">
                        <Button type="text" size="small" class="info-font-color">
                          <Icon type="ios-search"/>
                          查看
                        </Button>
                      </a>
                    </Col>
                    <Divider/>
                  </Row>
                  <Row type="flex" justify="center" align="middle">
                    <router-link to="/me?selected=5">
                      <Button type="text" size="small" class="main-font-color" >
                        查看更多
                      </Button>
                    </router-link>
                  </Row>
                </div>
              </TabPane>
            </Tabs>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div id="login" class="col" v-if="!login_flag">
        <router-link to="/login"><span>登录</span></router-link>
        <!--/-->
        <!--<router-link to="/register"><span>注册</span></router-link>-->
      </div>
      <div id="user" class="col" v-if="login_flag">
        <Dropdown>
          <a href="javascript:void(0)">
            <Badge>
              <Avatar v-if="user.avatar" :src="user.avatar"/>
              <span class="ml-5">{{ user.name }}</span>
              <Icon type="ios-arrow-down"/>
            </Badge>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem>
              <router-link to="/me" class="color515a6e">
                <span><Icon type="ios-contact-outline"/> 个人信息</span>
              </router-link>
            </DropdownItem>
            <!-- <DropdownItem>
              <router-link to="/update-log" class="color515a6e">
                <span><Icon type="ios-git-merge" /> 更新日志</span>
              </router-link>
            </DropdownItem> -->
            <!-- <DropdownItem>
              <router-link to="/help-manage" class="color515a6e">
                <span><Icon type="ios-help-circle-outline" /> 帮助中心</span>
              </router-link>
            </DropdownItem> -->
            <DropdownItem>
              <span @click="getOut"> <Icon type="ios-log-out"/> 退出登录</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import authority from '@/mixin/authority';
import * as companyUtil from '@/utils/company';
import * as loginUtil from '@/utils/login';

export default {
  name: 'MainHeader',
  data() {
    return {
      title_selected: '',
      login_flag: false,
      user: {
        name: '',
        avatar: '',
        base_id:0,
      },
      theme1: 'primary',
      company_name: '',
      account: {
        type: '',
        expire_date: '',
        left_file_capacity_num: 0,
      },
      info_visible: false,
      msg: {
        export: []
      },
      userRights: {},
      moudleAllrights: {
        common_setting: false,
        financial_center: false,
        sale_manage: false,
        buy_manage: false,
        // create_manage: false,
        product_manage: false,
      },
      moduleName: [
        'common_setting',
        'financial_center',
        'sale_manage',
        'buy_manage',
        // 'create_manage',
        'product_manage'
      ],
    };
  },
  methods: {
    //获取用户信息
    getUserInfo() {
      this.$axios.get('/api/user/info').then((response) => {
        if (response.data.err_no == 0) {
          if (response.data.noRights) {
            location.href = "/";
            localStorage.clear();
            return;
          }

          let data = response.data.results;
          this.user.name = data.name;
          this.user.avatar = data.avatar;
          this.user.base_id = data.baseId;
          localStorage.setItem('user_info', JSON.stringify(this.user));
          localStorage.setItem('user_uniq_id', data.user_uniq_id);//顶级账号在前端的唯一标识
          this.login_flag = true;

          //权限格式化
          this.rightsFormat(data.rights);
        } else {
          this.login_flag = false;
          localStorage.setItem('user_info', null);
        }
      });
    },
    rightsFormat(rights) {
      this.userRights = {};

      //特殊权限处理
      localStorage.setItem('special_rights_cost_price', 0);
      if (rights.includes('cost_price')) {
        localStorage.setItem('special_rights_cost_price', 1);
      }
      localStorage.setItem('special_rights_gross_profit', 0);
      if (rights.includes('gross_profit')) {
        localStorage.setItem('special_rights_gross_profit', 1);
      }

      this.getModuleRights(rights, this.moduleName);

      localStorage.setItem('user_rights', JSON.stringify(this.userRights));

      //检验各模块是否有权限
      this.setModuleAllRights(this.moduleName);
    },
    getModuleRights(rights, moduleNames = []) {
      for (let m = 0; m < moduleNames.length; m++) {
        let moduleName = moduleNames[m];
        for (let i = 0; i < authority[moduleName].length; i++) {
          for (let j = 0; j < authority[moduleName][i].rights.length; j++) {
            if (rights.includes(authority[moduleName][i].rights[j])) {
              if (this.userRights[moduleName] === undefined) {
                this.userRights[moduleName] = {};
              }

              if (this.userRights[moduleName].module === undefined) {
                this.userRights[moduleName].module = {};
              }

              if (this.userRights[moduleName].rights === undefined) {
                this.userRights[moduleName].rights = {};
              }

              if (this.userRights[moduleName].page === undefined) {
                this.userRights[moduleName].page = {};
              }

              this.userRights[moduleName].page[authority[moduleName][i].page_name] = authority[moduleName][i].page_name;
              this.userRights[moduleName].module[authority[moduleName][i].module_name] = authority[moduleName][i].module_name;
              this.userRights[moduleName].rights[authority[moduleName][i].rights[j]] = authority[moduleName][i].rights[j];
            }
          }
        }
      }
    },
    setModuleAllRights: function (plateNames) {
      for (let m = 0; m < plateNames.length; m++) {
        this.moudleAllrights[plateNames[m]] = this.aCheck.moduleAllRightCheck(plateNames[m]);
      }
    },
    //获取单位信息
    getCompanyInfo() {
      companyUtil.getCompany().then(res => {
        if (res.simple_name !== undefined) {
          this.company_name = res.simple_name;
        } else {
          this.company_name = '禾晓管理'
        }
      });
    },

    getAccountInfo() {
      this.$axios.get('/api/account/info').then((response) => {
        if (response.data.err_no == 0) {
          this.account = response.data.results;

          localStorage.setItem('left_file_capacity', this.account.left_file_capacity_num);

          let curTime = new Date().getTime();
          let expireDate = new Date(this.account.expire_date).getTime();
          if (curTime > expireDate) {//过期
            localStorage.setItem("Me", 4)//设置选中个人中心
            if (this.title_selected !== 'me') {
              location.href = "/me";
            }
          }
        }
      });

    },
    getOut() {
      this.$axios.post('/api/user/out').then((response) => {
        if (response.data.err_no == 0) {
          loginUtil.logout();
          location.href = "/login";
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    infoHandleOpen() {
      this.info_visible = true;
      if (!this.msg.is_read) {
        this.readUserMsg();
        this.msg.is_read = 1;
      }
    },
    readUserMsg() {
      this.$axios.post('/api/common/readUserMsg');
    },
    infoHandleClose() {
      this.info_visible = false;
    },
    getUserMsg() {
      setTimeout(()=>{
        //获取用户消息
        this.$axios.post('/api/common/getUserMsg').then((response) => {
          if (response.data.err_no == 0) {
            this.msg.is_read = response.data.results.is_read;
            this.msg.export = response.data.results.exportList.list;
          }
        });
      }, 0)
    },
    continueGetUserMsg() {
      setInterval(() => {
        this.getUserMsg();
      }, 1000 * 60 * 3);//三分钟
    },
    dataView() {
      if (this.$route.path == '/DataView') {
        this.nologin = true;
      }
    }
  },
  mounted() {
    this.getCompanyInfo();
    this.getUserInfo();
    this.getAccountInfo();
    this.getUserMsg();
    this.continueGetUserMsg();
  },
  watch: {
    '$route.name': function (newName, oldName) {
      this.title_selected = newName;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  height: 50px;
  vertical-align: inherit;
  /*background-color: #2d8cf0;*/
  background-color: #2775ff; /*主题颜色更改*/
}

.color515a6e {
  color: #515a6e !important;
}

#header_info {
  width: 100%;
  margin: 0 auto;
  color: #fff;
  overflow: hidden;
}

#logo {
  width: 150px;
  position: absolute;
  left: 0;
}

#menu {
  min-width: 800px;
  position: absolute;
  left: 151px;
}

#menu .ivu-menu-primary {
  background: #2775ff; /*主题颜色更改*/
}

.ivu-menu-horizontal {
  height: 50px;
  line-height: 50px;
}

#login, #user {
  right: 10px;
  width: 150px;
  text-align: center;
  position: absolute;
}

#tools {
  right: 160px;
  top: 15px;
  width: 20px;
  text-align: center;
  position: absolute;
  height: 20px;
  line-height: 20px;
}

.col {
  height: 50px;
  line-height: 50px;
}

.web_name {
  font-size: 22px;
  line-height: 50px;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.web_name > a, #login a, #user a, #tools a {
  color: #fff;
}

.module_title {
  margin-left: 30px !important;
}

.module_title > div {
  font-size: 16px;
  cursor: pointer;
  line-height: 48px;
  margin: 0 20px 0 20px;
  float: left;
}

.module_title_selected {
  border-bottom: 2px solid #fff;
}

.avatarChar {
  font-weight: bold;
  color: #2d8cf0;
  background-color: #5e99ff;
  font-size: 14px;
}

#user .ivu-badge {
  display: unset;
}

.ivu-menu-child-item-active {
  background: #2775ff;
}

.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-horizontal .ivu-menu-submenu {
  width: 100px;
  padding: 0 20px;
  margin-left: 15px;
}

.ivu-menu-item-active {
  font-weight: 700;
  border-bottom: 3px solid #fff;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  line-height: 44px;
  position: relative;
  margin-top: 3px !important;
}

.ivu-menu {
  text-align: center;
}

#tools .list {
  text-align: left;
  padding: 10px 10px;
}


.ivu-btn-small {
  font-size: 12px !important;
}

.ivu-avatar {
  height: 26px;
  width: 26px;
  line-height: 26px;
}

</style>
<style>
#tools .ivu-select-dropdown {
  width: 300px;
}
</style>
