import Vue from 'vue';
import Router from 'vue-router';

import User from '@/router/user';
import Index from '@/router/index';
import GoodsManage from '@/router/goodsManage';
import BuyManage from '@/router/buyManage';
import SaleManage from '@/router/saleManage';
import Finance from '@/router/finance';
import Setting from '@/router/setting';
import Help from '@/router/help';
import Common from '@/router/common';


// 注册
Vue.use(Router);

/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error=> error)
};

//导出模块
export default new Router({
    mode: 'history',
    routes: [
        ...Index,
        ...User,
		...GoodsManage,
		...BuyManage,
		...Setting,
		...Finance,
		...SaleManage,
        ...Help,
        ...Common
    ]
})
